.main-container {
  background-image: linear-gradient(90deg, #f80, #ff5700);
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.main-logo {
  width: 60px;
  height: auto;
}

.main-card {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid #cdcdcd;
  background-color: #fff;
  font-size: 14px;
}

.main-header {
  font-size: 150%;
  font-weight: 700;
}

input {
  accent-color: #ff6200;
}

.btn-primary {
  background-color: #ff6200 !important;
  border: 1px solid #ff6200 !important;
}

.form-control {
  &:focus {
    border-color: #f55300 !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(255, 0, 0, 0.2) !important;
  }
}

.form-select {
  &:focus {
    border-color: #f55300 !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(255, 0, 0, 0.2) !important;
  }
}
